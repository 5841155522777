
import {
  dispatchGetActivePackage,
  dispatchGetCompanyStats,
  dispatchGetPackages,
  dispatchGetPackagesTerms,
  dispatchGetQualification,
  dispatchMakeProfixoneLink,
  dispAuthFetch,
} from "@/store/mlm/actions";
import {
  readActivePackage,
  readCompanyStats,
  readPackagesList,
  readQualification,
} from "@/store/mlm/getters";
import { Component, Vue } from "vue-property-decorator";
import PackagePaymentModal from "@/components/Main/PackagePaymentModal.vue";
import MyPromocodeModal from "@/components/Main/MyPromocodeModal.vue";
import ProvideProfileDetailsModal from "@/components/Main/ProvideProfileDetailsModal.vue";
import MaintenanceModal from "@/components/Main/MaintenanceModal.vue";
import DexPromoDetailModal from "@/components/Main/DexPromoDetailModal.vue";
import EthicsCodexModal from "@/components/Main/EthicsCodexModal.vue";
import ActivatePromocode from "@/components/Main/ActivatePromocode.vue";
import ValidatorModal from "@/components/Main/ValidatorModal.vue";
import { IDexSummerPromo, IPackage } from "@/interfaces/mlm";
import { readUserProfile } from "@/store/main/getters";

@Component({
  components: {
    PackagePaymentModal,
    ValidatorModal,
    MaintenanceModal,
    MyPromocodeModal,
    ActivatePromocode,
    DexPromoDetailModal,
    EthicsCodexModal,
    ProvideProfileDetailsModal,
  },
})
export default class Dashboard extends Vue {
  public isProduction: boolean = process.env.VUE_APP_ENV === "production";
  public showPromocodeModal: boolean = false;
  public showProfileDetailsModal: boolean = false;
  public showMaintenanceModal: boolean = false;
  public showActivatePromocodeModal: boolean = false;
  public showPackagePaymentModal: boolean = false;
  public showPromoDetailModal: boolean = false;
  public showECodexModal: boolean = false;
  public showValidatorModal: boolean = false;
  public requestedShowPromo: boolean = false;
  public selectedPackage: IPackage | null = null;
  public packagesTerms: any[] = [];
  public timeInterval: any = null;
  public promoStatus: IDexSummerPromo | null = null;

  public validatorInfo: {
    available_count: number;
    available: boolean;
    own: number;
    left: number;
  } = {
    available_count: 0,
    available: false,
    own: 0,
    left: 0,
  };

  public beforeRouteLeave(to, from, next) {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
      this.timeInterval = null;
    }
    next();
  }

  public async mounted() {
    await dispatchGetPackages(this.$store);
    this.packagesTerms = await dispatchGetPackagesTerms(this.$store);
    await dispatchGetActivePackage(this.$store);
    await dispatchGetQualification(this.$store);
    this.updatePromoInfo();
    if (
      this.activePackage &&
      this.activePackage.lost_profit > 0 &&
      this.activePackage.expire_dt
    ) {
      this.initializeClock(this.activePackage.expire_dt);
    }
    if (
      this.activePackage &&
      this.userProfile &&
      (!this.userProfile.first_name ||
        !this.userProfile.last_name ||
        !this.userProfile.tg_username)
    ) {
      this.showProfileDetailsModal = true;
    }
    if (new Date() < new Date("2024-03-10 21:00:00")) {
      const informedMaintenance = localStorage.getItem("informedMaintenance");
      if (!informedMaintenance) {
        this.showMaintenanceModal = true;
      }
    }
    if (this.userProfile?.code_of_etics_status === "old") {
      this.showECodexModal = true;
    }

    const validatorResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/mlm/validation_licence/check_available/",
      init: {
        method: "POST",
        body: {},
      },
    });
    if (validatorResp.status === 200) {
      this.validatorInfo = await validatorResp.json();
      console.log("validatorInfo", this.validatorInfo);
    }
  }

  public async updatePromoInfo() {
    if (this.$route.path.includes("dashboard")) {
      await dispatchGetCompanyStats(this.$store);
      setTimeout(() => {
        this.updatePromoInfo();
      }, 60000);
    }
  }

  public getTimeRemaining(endtime) {
    // @ts-ignore
    const total = Date.parse(endtime) - new Date();

    const seconds = total > 0 ? Math.floor((total / 1000) % 60) : "00";
    const minutes = total > 0 ? Math.floor((total / 1000 / 60) % 60) : "00";
    const hours =
      total > 0 ? Math.floor((total / (1000 * 60 * 60)) % 24) : "00";
    const days = total > 0 ? Math.floor(total / (1000 * 60 * 60 * 24)) : "00";

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  public initializeClock(endtime) {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
      this.timeInterval = null;
    }

    this.timeInterval = setInterval(() => {
      const { total, days, minutes, hours, seconds } =
        this.getTimeRemaining(endtime);
      // @ts-ignore
      this.$refs["lost_profit_days"].innerHTML = `${days}`;
      // @ts-ignore
      this.$refs["lost_profit_hours"].innerHTML = `${hours}`;
      // @ts-ignore
      this.$refs["lost_profit_minutes"].innerHTML = `${minutes}`;
      // @ts-ignore
      this.$refs["lost_profit_seconds"].innerHTML = `${seconds}`;
      if (total <= 0) {
        if (this.timeInterval) {
          clearInterval(this.timeInterval);
          this.timeInterval = null;
        }
      }
    }, 1000);
  }

  public handleOpenValidatorModal() {
    this.showValidatorModal = true;
  }

  public handleCloseValidatorModal() {
    this.showValidatorModal = false;
  }

  public handleOpenPackagePaymentModal(pack) {
    this.selectedPackage = pack;
    this.showPackagePaymentModal = true;
  }

  public handleClosePackagePaymentModal() {
    this.showPackagePaymentModal = false;
    this.selectedPackage = null;
  }

  public handleOpenPromocodeModal() {
    if (this.userProfile?.code_of_etics_status !== "chk") {
      this.showECodexModal = true;
      this.requestedShowPromo = true;
      return;
    }
    this.showPromocodeModal = true;
  }

  public handleClosePromocodeModal() {
    this.showPromocodeModal = false;
  }

  public handleCloseECodexModal(result) {
    this.showECodexModal = false;
    if (result && this.requestedShowPromo) {
      this.showPromocodeModal = true;
    }
  }

  public handleCloseProfileDetailsModal() {
    this.showProfileDetailsModal = false;
  }

  public handleOpenActivatePromocodeModal() {
    this.showActivatePromocodeModal = true;
  }

  public handleCloseMaintenanceeModal() {
    this.showMaintenanceModal = false;
    localStorage.setItem("informedMaintenance", "true");
  }

  public async handleOpenProfix() {
    const result = await dispatchMakeProfixoneLink(this.$store);
    if (result && result.url) {
      window.location.href = result.url;
    }
  }

  public async handleCloseActivatePromocodeModal(updated: boolean = false) {
    this.showActivatePromocodeModal = false;
    if (updated) {
      await dispatchGetPackages(this.$store);
      await dispatchGetActivePackage(this.$store);
      if (
        this.activePackage &&
        this.activePackage.lost_profit > 0 &&
        this.activePackage.expire_dt
      ) {
        this.initializeClock(this.activePackage.expire_dt);
      }
    }
  }

  get packagesList() {
    return readPackagesList(this.$store);
  }

  get activePackage() {
    return readActivePackage(this.$store);
  }

  get qualification() {
    return readQualification(this.$store);
  }

  get companyStats() {
    return readCompanyStats(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get promoProgress() {
    if (this.promoStatus && this.promoStatus.progress) {
      if (this.promoStatus.progress <= 50) {
        let v = (this.promoStatus.progress / 50) * 100;
        return 33 * (v / 100);
      } else if (this.promoStatus.progress <= 75) {
        let v = (this.promoStatus.progress / 75) * 100;
        return 67 * (v / 100);
      } else {
        return this.promoStatus.progress;
      }
    }
    return 0;
  }
}
