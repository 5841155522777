
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class ValidatorInfoModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;

  public isRestrictedSecected = true;

  public closeModal() {
    this.$emit("close-modal");
  }
}
