
import { Component, Vue, Prop } from "vue-property-decorator";
import ValidatorInfoModal from "@/components/Main/ValidatorInfoModal.vue";
import { dispAuthFetch } from "@/store/mlm/actions";
import { dispatchGetUserProfile } from "@/store/main/actions";
import { readUserProfile } from "@/store/main/getters";

import type { IInvoiceResponse } from "@/interfaces/mlm";

@Component({
  components: {
    ValidatorInfoModal,
  },
})
export default class ValidatorModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;

  public infoModalShow: boolean = false;

  public validatorUnitPrice: number = 1500;
  public validatorDexUnitPrice: number = 1200;
  public validatorDexNetPrice: number = 0;
  public validatorPromocode: string = "HereWillBePromocode";

  public currentStage: 0 | 1 | 2 = 0;

  public paymentStandart: "BEP20" = "BEP20";
  public paymentMethod: "USDT" | "DEXNET" = "USDT";

  public invoiceResponse: IInvoiceResponse | null = null;
  public transactionResponse: { paid: boolean; confirmations: number } | null =
    null;
  private paymentStatusTimeout: number | null = null;

  public async mounted() {
    await dispatchGetUserProfile(this.$store);
    const validatorResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/payments/get_dexnet_price/",
      init: {
        method: "GET",
      },
    });
    if (validatorResp.status === 200) {
      const data = await validatorResp.json();
      this.validatorDexNetPrice = data.price;
    }
  }

  public async handlePurchase() {
    const invoiceResp = await dispAuthFetch(this.$store, {
      input: "/api/v1/mlm/validation_licence/purchase/request/",
      init: {
        method: "POST",
        body: {
          standart: this.paymentStandart,
          token: this.paymentMethod,
        },
      },
    });

    if (invoiceResp.status === 200) {
      this.invoiceResponse = await invoiceResp.json();
      if (this.paymentMethod === "USDT") {
        if (
          this.userProfile &&
          this.userProfile?.balances.units >= this.validatorUnitPrice
        ) {
          this.confirmInvoice();
        }
      } else {
        if (
          this.userProfile &&
          this.userProfile?.balances.dtin >= this.validatorDexNetPrice
        ) {
          this.confirmInvoice();
        } else {
          this.currentStage = 1;
          this.getPaymentStatus();
        }
      }
    } else {
      this.$toast.error("Failed to create invoice, please try again later", {
        message: "Failed to create invoice, please try again later",
        duration: 3000,
        position: "bottom",
      });
    }
  }

  async getPaymentStatus() {
    const statusResponsce = await dispAuthFetch(this.$store, {
      input: "/api/v1/payments/get_payment_status/",
      init: {
        method: "POST",
        body: {
          address: this.invoiceResponse?.address,
          invoice: this.invoiceResponse?.invoice.id,
        },
      },
    });
    if (statusResponsce.status === 200) {
      const data = await statusResponsce.json();

      if (data.transaction) {
        this.transactionResponse = data.transaction;
        if (data.transaction.paid) {
          this.confirmInvoice();
        } else {
          this.scheduleNextPaymentStatusCheck();
        }
      } else {
        this.scheduleNextPaymentStatusCheck();
      }
    }
  }

  async confirmInvoice() {
    const confirmResponse = await dispAuthFetch(this.$store, {
      input: "/api/v1/mlm/validation_licence/purchase/confirm/",
      init: {
        method: "POST",
        body: {
          invoice: this.invoiceResponse?.invoice.id,
        },
      },
    });
    if (confirmResponse.status === 200) {
      const confirmData = await confirmResponse.json();
      if (!this.invoiceResponse) return;
      this.invoiceResponse.invoice = confirmData;
      this.validatorPromocode = confirmData.comment_params.activation_key;
      this.currentStage = 2;
    }
  }

  scheduleNextPaymentStatusCheck() {
    this.clearPaymentStatusTimeout();
    this.paymentStatusTimeout = window.setTimeout(
      () => this.getPaymentStatus(),
      3000
    );
  }

  clearPaymentStatusTimeout() {
    if (this.paymentStatusTimeout !== null) {
      clearTimeout(this.paymentStatusTimeout);
      this.paymentStatusTimeout = null;
    }
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  public closeModal() {
    this.clearPaymentStatusTimeout();
    this.currentStage = 0;
    this.$emit("close-modal");
  }

  public async destroy() {
    this.clearPaymentStatusTimeout();
  }
}
